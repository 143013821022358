import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import fgts1 from 'assets/images/fgts1.jpg';
import fgts2 from 'assets/images/fgts2.jpg';
import GooglePlay from 'assets/images/google-play.png';
import AppleStore from 'assets/images/apple-store.png';
import Success from 'assets/images/success.png';
import { Checkbox } from 'components/Form/Checkbox';

import { useSimulationContext } from 'context/simulation/useSimulationContext';
import { Button } from 'components/Button';
import { ProgressBar } from 'components/ProgressBar';
import { Typography } from 'components/Typography';
import { Flex } from 'components/Flex';
import { usePromoterContext } from 'context/promoter/usePromoterContext';

import {
  Container,
  Conditions,
  FormFooter,
  Title,
  Text,
  Row,
  ImgFgts,
  Col,
  TitlePrimary,
  A,
  ContainerCaptcha,
} from './styles';
import { ISimulationResult } from './types';
import { TextCenter } from '../ProposalResult/styles';

export const QuestionFgts1 = ({ setWizzardStep }: ISimulationResult) => {
  const { handleSimulate, create, setForm, form } = useSimulationContext();
  const { promoter } = usePromoterContext();
  const [btnBlock, setBtnBlock] = useState(true);
  const [activeBank, setActiveBank] = useState(false);
  const [activeAniversary, setActiveAniversary] = useState(false);
  const [nameBank, setNameBank] = useState('');
  const [nameBankCaixa, setNameBankCaixa] = useState('');

  const setTokenCaptcha = async (tokenCaptcha: any) => {
    if (tokenCaptcha) {
      setForm({
        ...form,
        token: tokenCaptcha,
      });

      console.log(activeAniversary, activeBank);

      if (activeAniversary && activeBank) {
        setBtnBlock(false);
      }
    }
  };

  useEffect(() => {
    setForm({
      ...form,
      token: '',
    });

    switch (promoter?.promoterStyles.bank) {
      case '626':
        setNameBank('C6 BANK');
        setNameBankCaixa('C6 CONSIGNADO S.A.');
        break;
      case '149':
        setNameBank('FACTA FINANCEIRA');
        setNameBankCaixa('FACTA FINANCEIRA S.A.');
        break;
      case '623':
        setNameBank('BANCO PAN');
        setNameBankCaixa('BANCO PAN S.A.');
        break;
      case '329':
        setNameBank('ICRED');
        setNameBankCaixa('QI SOCIEDADE DE CREDITO DIRETO S.A.');
        break;
      case '329-1':
        setNameBank('GRANDINOBANK');
        setNameBankCaixa('QI SOCIEDADE DE CREDITO DIRETO S.A.');
        break;
      case '329-5':
        setNameBank('GRANDINOBANK');
        setNameBankCaixa('QI SOCIEDADE DE CREDITO DIRETO S.A.');
        break;
      case '465':
        setNameBank('QUERO+CRÉDITO');
        setNameBankCaixa('CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A.');
        break;
      case '389':
        setNameBank('MERCANTIL DO BRASIL');
        setNameBankCaixa('BANCO MERCANTIL DO BRASIL SA');
        break;
      case '318':
        setNameBank('BMG');
        setNameBankCaixa('BANCO BMG S.A.');
        break;
      case '324':
        setNameBank('LOTUS MAIS');
        setNameBankCaixa('CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.');
        break;
      case '274':
        setNameBank('NOVO SAQUE');
        setNameBankCaixa('QISTA S.A.');
        break;
      case '329-3':
        setNameBank('PRATA DIGITAL');
        setNameBankCaixa('QI SOCIEDADE DE CREDITO DIRETO S.A.');
        break;
      case '329-6':
        setNameBank('VCTEX');
        setNameBankCaixa('QI SOCIEDADE DE CREDITO DIRETO S.A.');
        break;
      case '329-4':
        setNameBank('V8 DIGITAL');
        setNameBankCaixa('QI SOCIEDADE DE CREDITO DIRETO S.A.');
        break;
      case '531':
        setNameBank('PRESENÇABANK');
        setNameBankCaixa('BMP e MONEYPLUS');
        break;
      default:
        setNameBank('SAFRA');
        setNameBankCaixa('J. SAFRA S.A');
        break;
    }
  }, []);

  const handleContinue = () => {
    setForm({ ...form });

    const data = {
      id: promoter?.promoterStyles.id as string,
      cpf: form.cpf,
      phone: form.phone,
      birthDate: form.birthDate,
      name: form.name,
      token: form.token ?? '',
      indication: form.indication ?? '',
    };

    handleSimulate(data);

    setWizzardStep(2);
  };

  const handleCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setBtnBlock(true);

    if (event.target.name === 'activeAniversary') {
      setActiveAniversary(event.target.checked);
      if (activeBank && event.target.checked && form.token !== '') {
        setBtnBlock(false);
      }
    }
    if (event.target.name === 'activeBank') {
      setActiveBank(event.target.checked);
      if (activeAniversary && event.target.checked && form.token !== '') {
        setBtnBlock(false);
      }
    }
  };

  if (create.status === 'loading') {
    return (
      <>
        <Flex justifyContent="flex-start" alignItems="center" noWrap>
          <ProgressBar />
          <Flex
            margin="0 0 0 30px"
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Typography color="default" size={35} fontWeight={700}>
              Realizando Simulação
            </Typography>
            <Typography color="default" size={15}>
              Por favor aguarde
            </Typography>
          </Flex>
        </Flex>
      </>
    );
  }

  const content = () => {
    return (
      <Container>
        <Conditions>
          <TextCenter>
            <ImgFgts
              src={Success}
              style={{ marginRight: '5px', boxShadow: 'none', width: '120px' }}
            />
            <TitlePrimary>Falta pouco para você finalizar!</TitlePrimary>
            <Text style={{ fontSize: '18px' }}>
              Libere o saque-aniverário no aplicativo do FGTS.
            </Text>
            <Text style={{ fontSize: '18px' }}>Siga os passos abaixo.</Text>
          </TextCenter>
          <Row>
            <Col>
              <ImgFgts src={fgts1} />
            </Col>
            <Col>
              <Title>HABILITE O SAQUE-ANIVERSÁRIO</Title>
              <Text>1. Acesse seu aplicativo FGTS</Text>
              <Row style={{ margin: 0, border: 'none', padding: 0 }}>
                <A
                  href="https://play.google.com/store/apps/details?id=br.gov.caixa.fgts.trabalhador"
                  target="_blank"
                >
                  <ImgFgts src={GooglePlay} style={{ marginRight: '5px' }} />
                </A>
                <A
                  href="https://apps.apple.com/br/app/fgts/id1038441027"
                  target="_blank"
                >
                  <ImgFgts src={AppleStore} />
                </A>
              </Row>
              <Text style={{ marginTop: '15px' }}>
                2. Faça login no aplicativo do FGTS.
              </Text>
              <Text>3. Escolha a opção de Saque Aniversário do FGTS.</Text>
              <Text>4. Ative a modalidade SAQUE ANIVERSÁRIO.</Text>
            </Col>
          </Row>
        </Conditions>
        <Conditions>
          <Row>
            <Col>
              <ImgFgts src={fgts2} />
            </Col>
            <Col>
              <Title>{`AUTORIZE O BANCO ${nameBankCaixa}`}</Title>
              <Text>{`4. Autorize o Banco ${nameBankCaixa} a consultar seus dados no aplicativo do FGTS.`}</Text>
            </Col>
          </Row>
        </Conditions>
        <Conditions>
          <Row style={{ display: 'block' }}>
            <Title>Pontos importantes</Title>
            <Checkbox
              id="activeAniversary"
              name="activeAniversary"
              label="Ativei a modalidade saque-aniversário"
              onChange={handleCheck}
              margin="20px 0 0 0"
            />
            <Checkbox
              id="activeBank"
              name="activeBank"
              label="Ativei a consulta dos meus dados no aplicativo do FGTS"
              onChange={handleCheck}
              margin="20px 0 0 0"
            />
          </Row>

          <ContainerCaptcha>
            {/* <HCaptcha
              sitekey="7e6889d2-5131-4600-b503-c9d3aaa7c432"
              onVerify={setTokenCaptcha}
            /> */}
            <ReCAPTCHA
              sitekey="6LczAu4dAAAAANxkvoTPgkhuC7HfowUwvxZMO8dV"
              onChange={setTokenCaptcha}
            />
          </ContainerCaptcha>
        </Conditions>
        <FormFooter>
          <Button
            id="btn-simular-novamente"
            type="button"
            label="Simular novamente"
            onClick={handleContinue}
            disabled={btnBlock}
          />
        </FormFooter>
        <FormFooter>
          <Button
            style={{ background: '#c2c2c2' }}
            type="button"
            label="Sair"
            onClick={() => setWizzardStep(1)}
          />
        </FormFooter>
      </Container>
    );
  };

  return content();
};
