import { useEffect, useState } from 'react';

import CSeisLogo from 'assets/images/c6.png';
import SafraLogo from 'assets/images/safra.png';
import FactaLogo from 'assets/images/facta.png';
import PANLogo from 'assets/images/pan.png';
import MERCANTILLogo from 'assets/images/logo-mercantil.jpg';
import ICRED from 'assets/images/icred.png';
import GRANDINO from 'assets/images/grandino.png';
import QUEROMAIS from 'assets/images/queromais.png';
import BMG from 'assets/images/bmg.png';
import LOTUS from 'assets/images/lotusmais.png';
import NOVOSAQUE from 'assets/images/novosaque.png';
import V8DIGITAL from 'assets/images/v8-digital.png';
import PRATADIGITAL from 'assets/images/prata-digital.png';
import VCTEX from 'assets/images/vctex.png';
import PRESENCABANK from 'assets/images/presencaBank.png';

import { usePromoterContext } from 'context/promoter/usePromoterContext';
import { useSimulationContext } from 'context/simulation/useSimulationContext';
import { Button } from 'components/Button';
import { ProgressBar } from 'components/ProgressBar';
import { Typography } from 'components/Typography';
import { Flex } from 'components/Flex';
import { WizzardSteps } from 'components/WizzardSteps';
import { Form } from 'components/Form/Form';
import { Input } from 'components/Form/Input';
import { currencyFormat } from 'utils/currencyFormat';
import { moneyMask } from 'utils/masks';

import {
  Container,
  Conditions,
  ConditionItem,
  FormFooter,
  BankLogo,
} from './styles';
import { ISimulationResult } from './types';

export const SimulationResult = ({
  setWizzardStep,
  step,
}: ISimulationResult) => {
  const { create, setForm, form } = useSimulationContext();
  const { promoter } = usePromoterContext();
  const [value, setValue] = useState(0);
  const [validValue, setValidValue] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const [logoBank, setLogoBank] = useState('');
  useEffect(() => {
    switch (promoter?.promoterStyles.bank) {
      case '626':
        setLogoBank(CSeisLogo);
        break;
      case '149':
        setLogoBank(FactaLogo);
        break;
      case '329':
        setLogoBank(ICRED);
        break;
      case '329-1':
        setLogoBank(GRANDINO);
        break;
      case '329-5':
        setLogoBank(GRANDINO);
        break;
      case '465':
        setLogoBank(QUEROMAIS);
        break;
      case '623':
        setLogoBank(PANLogo);
        break;
      case '389':
        setLogoBank(MERCANTILLogo);
        break;
      case '318':
        setLogoBank(BMG);
        break;
      case '324':
        setLogoBank(LOTUS);
        break;
      case '274':
        setLogoBank(NOVOSAQUE);
        break;
      case '329-3':
        setLogoBank(PRATADIGITAL);
        break;
      case '329-4':
        setLogoBank(V8DIGITAL);
        break;
      case '329-6':
        setLogoBank(VCTEX);
        break;
      case '531':
        setLogoBank(PRESENCABANK);
        break;
      default:
        setLogoBank(SafraLogo);
        break;
    }
  }, [logoBank]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: moneyMask(event.target.value),
    });
    setValue(Number(event.target.value.replace(/\D/g, '')) / 100);
    setValidValue(true);
  };

  const handleContinue = () => {
    let valueSolicited = value;
    const febrabanBank = create.data?.data?.result?.febrabanId;

    if (febrabanBank !== '626') {
      valueSolicited = Number(create.data?.data?.result?.totalCreditLiberty);
      setValue(valueSolicited);
    }

    // if (valueSolicited < 100) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Erro',
    //     text : 'O valor solicitado não pode ser menor que R$ 100,00'
    //   });
    //   setErrorMessage('O valor solicitado não pode ser menor que R$ 100,00');
    //   return setValidValue(false);
    // }
    if (Number(create?.data?.data?.result?.totalCreditLiberty) < value) {
      const valida = create?.data?.data?.result?.totalCreditLiberty;
      setErrorMessage(`O valor solicitado não pode ser superior a ${valida}`);
      return setValidValue(false);
    }
    if (febrabanBank !== '626') {
      setForm({
        ...form,
        idSimulation: create.data?.data?.result?.idSimulation,
        valueSolicited: create.data?.data?.result?.totalCreditLiberty,
      });
    } else {
      setForm({
        ...form,
        idSimulation: create.data?.data?.result?.idSimulation,
      });
    }

    setWizzardStep(3);
  };

  if (create.status === 'loading') {
    return (
      <>
        <Flex justifyContent="flex-start" alignItems="center" noWrap>
          <ProgressBar />
          <Flex
            margin="0 0 0 30px"
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Typography color="default" size={35} fontWeight={700}>
              Realizando Simulação
            </Typography>
            <Typography color="default" size={15}>
              Por favor aguarde
            </Typography>
          </Flex>
        </Flex>
      </>
    );
  }

  if (create.status === 'error') {
    let ErrorMessage = create?.error?.response?.data?.message;

    if(ErrorMessage) {
      const NewError = ErrorMessage.split(' [');
      if(NewError && NewError.length > 0){
        ErrorMessage = NewError[0];
      }
    }


    switch (ErrorMessage) {
      case 'ERRO BANCO SAFRA: Trabalhador informado não possui contas de FGTS.':
      case 'ERRO BANCO SAFRA: Trabalhador não possui adesão ao saque aniversário vigente na data corrente.':
      case 'Erro: Cliente precisa liberar o saque aniversário no aplicativo do FGTS':
      case 'Trabalhador informado não possui contas de FGTS.':
      case 'Trabalhador não possui adesão ao saque aniversário vigente na data corrente.':
      case 'Instituição não autorizada pelo cliente!':
      case 'Cliente não autorizou a instituição financeira a realizar a consulta.':
      case 'Presença Bank Informa: Cliente não autorizou a instituição financeira a realizar a consulta.':
        setWizzardStep(7);
        break;
      case 'ERRO BANCO SAFRA: Instituição Fiduciária não possui autorização do Trabalhador para Operação Fiduciária.':
      case 'Lotus Mais Informa: Instituição Fiduciária não possui autorização do Trabalhador para Operação Fiduciária. [403]':
      case 'Instituição Fiduciária não possui autorização do Trabalhador para Operação Fiduciária.':
      case 'Erro: BancoC6 não foi liberado no aplicativo da Caixa, é preciso realizar a liberação':
      case 'Grandino Bank Informa: Instituição não autorizada pelo cliente!':
      case 'Presença Bank Informa: Cliente não autorizou a instituição financeira a realizar a consulta.':
        setWizzardStep(8);
        break;
      default:
        setWizzardStep(1);
        break;
    }
  }

  const content = () => {
    return (
      <Container>
        <Flex margin="0 0 20px 0" direction="column" alignItems="flex-start">
          <Typography color="default" size={35} fontWeight={700}>
            Proposta
          </Typography>
          <Typography color="default" size={15}>
            Peça agora seu adiantamento, confira as condições
          </Typography>
        </Flex>
        <WizzardSteps steps={6} step={step} />
        <BankLogo src={logoBank} alt="bank" />
        <Conditions>
          <ConditionItem>
            <Typography color="default" size={14}>
              Valor do contrato
            </Typography>
            <Typography color="alert" size={17} fontWeight={700}>
              {currencyFormat(
                create.data?.data?.result?.totalCreditAccountFGTS
              )}
            </Typography>
          </ConditionItem>

          <ConditionItem>
            <Typography color="default" size={14}>
              Descontos
            </Typography>
            <Typography color="alert" size={17} fontWeight={700}>
              {currencyFormat(create.data?.data?.result?.totalIOF)}
            </Typography>
          </ConditionItem>

          <ConditionItem>
            <Typography color="default" size={14}>
              Total liberado para saque
            </Typography>
            <Typography color="alert" size={40} fontWeight={700}>
              {currencyFormat(create.data?.data?.result?.totalCreditLiberty)}
            </Typography>
          </ConditionItem>
        </Conditions>
        {create.data?.data?.result?.febrabanId === '626' && (
          <Flex margin="20px 0 0 0">
            <Form onSubmit={handleContinue}>
              <Input
                id="valueSolicited"
                name="valueSolicited"
                label="Quanto deseja sacar?"
                required
                autoFocus
                value={form.valueSolicited}
                onChange={handleChange}
                isError={!validValue}
                errorMessage={errorMessage}
              />
            </Form>
          </Flex>
        )}
        <FormFooter>
          <Button
            id="btn-confirmacao"
            type="button"
            label="Quero contratar"
            onClick={handleContinue}
          />
        </FormFooter>
      </Container>
    );
  };

  return content();
};
